// constants.js - Contains all constants and utility functions
import { useUser } from "../../Context/UserContext";

export const KominkaMarker = "/images/Kominka/KominkaMark.png";
export const AkiyaAkiya = "/images/prefecture/AkiyaAkiya.png";

export const MAP_CONTAINER_STYLE = {
  width: "100%",
  height: "500px",
  borderRadius: "12px",
  boxShadow:
    "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
};

export const EXPANDED_MAP_STYLE = {
  width: "100%",
  height: "700px",
  borderRadius: "12px",
  boxShadow:
    "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
};

export const CENTER = { lat: 35.6895, lng: 139.6917 };
export const API_KEY =
  process.env.REACT_APP_GOOGLE_MAPS_API_KEY ||
  "AIzaSyDhd_bCluPIx2cSHS7pGd5wVPkIRU7rpxQ";

// Data format conversion utilities
export const backendToComponentFormat = (locations) => {
  if (!locations) return [];
  return locations.map((loc) => ({
    lat: loc.coordinate[0],
    lng: loc.coordinate[1],
    radius: loc.proximity,
  }));
};

export const componentToBackendFormat = (location) => {
  return {
    coordinate: [location.lat, location.lng],
    proximity: location.radius,
  };
};
