// UIComponents.js

import React from "react";

export const ErrorMessage = ({ error, onClose }) => {
  if (!error) return null;
  
  return (
    <div className="w-full bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4">
      <span className="block sm:inline">{error}</span>
      <span
        className="absolute top-0 bottom-0 right-0 px-4 py-3 cursor-pointer"
        onClick={onClose}
      >
        <svg
          className="fill-current h-6 w-6 text-red-500"
          role="button"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <title>Close</title>
          <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
        </svg>
      </span>
    </div>
  );
};

export const LoadingIndicator = () => (
  <div className="w-full text-center py-4">
    <div className="inline-block animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-green-500"></div>
    <p className="mt-2 text-green-600">
      Loading your favorite locations...
    </p>
  </div>
);

export const EmptyStateMessage = ({ isFirstTimeUser }) => {
  if (!isFirstTimeUser) return null;
  
  return (
    <div className="w-full text-center py-4 bg-green-50 rounded-lg my-4">
      <p className="text-green-700 font-medium">
        Welcome! You haven't added any favorite locations yet.
      </p>
      <p className="text-gray-600 mt-2">
        Add up to 3 locations to receive updates about properties
        in these areas.
      </p>
    </div>
  );
};

export const RadiusInput = ({ radius, onChange }) => {
    const handleRadiusChange = (e) => {
      onChange(e);
    };
    
    return (
      <div className="flex flex-col w-full px-4 py-4 bg-white rounded-lg shadow">
        <div className="flex justify-between items-center mb-2">
          <label htmlFor="radius" className="text-gray-700 font-medium">
            Coverage Radius
          </label>
          <div className="bg-gray-100 px-3 py-1 rounded-md">
            <span className="font-semibold text-green-600">{radius} km</span>
          </div>
        </div>
        
        <div className="flex items-center w-full gap-4">
          <span className="text-sm text-gray-500">1km</span>
          <input
            type="range"
            id="radius"
            value={radius}
            onChange={handleRadiusChange}
            min="1"
            max="30"
            step="1"
            className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer accent-green-500"
          />
          <span className="text-sm text-gray-500">30km</span>
        </div>
        
        <div className="mt-3 text-sm text-gray-600">
          <p className="mb-1">
            <span className="font-medium text-gray-700">Search area:</span> Approximately {Math.round(Math.PI * radius * radius)} sq km
          </p>
          <div className="mt-2 p-2 bg-blue-50 rounded-md text-blue-700 text-xs">
            <p className="font-medium mb-1">Tips for setting your radius:</p>
            <ul className="list-disc pl-4 space-y-1">
              <li><strong>Small (1-5km):</strong> Specific town area, but may include fewer akiya banks</li>
              <li><strong>Medium (6-15km):</strong> Good balance - usually covers multiple municipalities</li>
              <li><strong>Large (16-30km):</strong> Maximum coverage, includes more municipalities but wider search area</li>
            </ul>
            <p className="mt-2 italic">As you adjust the radius, watch how the municipality count changes above.</p>
          </div>
        </div>
      </div>
    );
  };
  
export const ActionButtons = ({ 
  addingLocation, 
  isEditing, 
  onAddLocation, 
  onAddLocationClick, 
  onSaveChangesClick, 
  onDeleteLocationClick 
}) => (
  <div className="flex justify-center space-x-4">
    {addingLocation && (
      <button
        onClick={onAddLocationClick}
        className="px-6 py-2 bg-green-500 text-white rounded hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500"
      >
        Add Location
      </button>
    )}
    {isEditing && (
      <>
        <button
          onClick={onSaveChangesClick}
          className="px-6 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          Save Changes
        </button>
        <button
          onClick={onDeleteLocationClick}
          className="px-6 py-2 bg-red-500 text-white rounded hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500"
        >
          Delete Location
        </button>
      </>
    )}
    {!addingLocation && !isEditing && (
      <button
        onClick={onAddLocation}
        className="px-6 py-2 bg-purple-500 text-white rounded hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500"
      >
        Add New Location
      </button>
    )}
  </div>
);

export const ConfirmationDialog = ({ message, onConfirm, onCancel }) => (
  <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
    <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
      <div className="mt-3 text-center">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Confirmation
        </h3>
        <div className="mt-2">
          <p className="text-sm text-gray-500">{message}</p>
        </div>
        <div className="items-center px-4 py-3">
          <button
            className="px-4 py-2 bg-green-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-300"
            onClick={onConfirm}
          >
            Confirm
          </button>
          <button
            className="mt-3 px-4 py-2 bg-gray-200 text-gray-800 text-base font-medium rounded-md w-full shadow-sm hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-300"
            onClick={onCancel}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
);