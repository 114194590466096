import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const FirstSOVFullImage = "/images/MainLogo.webp";

function Single_property({ property, hideKnowMore }) {
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <Link to={`/property/${property.serialNumber}`} className="block">
      <div className='bg-white flex flex-col justify-between shadow-[rgba(0,0,0,0.05)_0px_0px_20px_0px] w-full md:min-h-[43rem] lg:min-h-[43rem] px-[2rem] sm:px-[0rem]'>
        <div className='mx-6 r_xs:mx-0 py-[3rem]'>
          <img
            className='w-full h-[18rem] object-cover'
            loading='lazy'
            alt={`Akiya2.0 - ${property.propertyName}`}
            src={imageLoaded ? property.images[0] : FirstSOVFullImage}
            onLoad={() => setImageLoaded(true)}
          />
          <h1 className='text-[#5ab963] text-[24px] mt-8'>{property.propertyName}</h1>
          <p className='text-[#949995] text-[16px] my-5'>{property.smallAbout}</p>
        </div>
        {!hideKnowMore && (
          <div>
            <button className='w-full flex justify-center items-center py-[1.3rem] bg-[#5ab963] text-white font-[800] text-[14px]'>Know More</button>
          </div>
        )}
      </div>
    </Link>
  );
}

export default Single_property;