// MapServices.js
import axios from "axios";
import { componentToBackendFormat, backendToComponentFormat } from "./constants";

// Add this new API function
export const calculateMunicipalities = async (locations) => {
  try {
    const response = await axios.post("/api/calculate-municipalities", {
      locations: locations.map(componentToBackendFormat),
    });

    return response.data.count;
  } catch (error) {
    console.error("Error calculating municipalities:", error);
    // Fallback calculation
    return Math.max(1, Math.round(
      locations.reduce((sum, loc) => sum + loc.radius * 0.8, 0)
    ));
  }
};

export const addFavoriteLocation = async (location, email) => {
  try {
    const backendLocation = componentToBackendFormat(location);
    const response = await axios.post(
      "https://app.akiya2.com/favorite-locations",
      backendLocation,
      {
        params: { email },
      }
    );

    if (response.data.success) {
      return {
        success: true,
        locations: backendToComponentFormat(
          response.data.fav_coordinates_and_proximity
        ),
      };
    }
    return { success: false, error: "Failed to add location" };
  } catch (err) {
    if (err.response && err.response.status === 400) {
      return { success: false, error: "Maximum of 3 favorite locations allowed" };
    }
    return { success: false, error: "Failed to add favorite location" };
  }
};

export const updateFavoriteLocation = async (oldLocation, newLocation, email) => {
  try {
    const request = {
      old_location: componentToBackendFormat(oldLocation),
      new_location: componentToBackendFormat(newLocation),
    };

    const response = await axios.put(
      "https://app.akiya2.com/favorite-locations/update",
      request,
      {
        params: { email },
      }
    );

    if (response.data.success) {
      return {
        success: true,
        locations: backendToComponentFormat(
          response.data.fav_coordinates_and_proximity
        ),
      };
    }
    return { success: false, error: "Failed to update location" };
  } catch (err) {
    return { success: false, error: "Failed to update favorite location" };
  }
};

export const deleteFavoriteLocation = async (location, email) => {
  try {
    const backendLocation = componentToBackendFormat(location);
    const response = await axios.delete(
      "https://app.akiya2.com/favorite-locations",
      {
        params: { email },
        data: backendLocation,
      }
    );
    
    if (response.data.success) {
      return {
        success: true,
        locations: backendToComponentFormat(
          response.data.fav_coordinates_and_proximity
        ),
      };
    }
    return { success: false, error: "Failed to delete location" };
  } catch (err) {
    return { success: false, error: "Failed to delete favorite location" };
  }
};