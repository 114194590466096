// CustomMarker.js
import React, { memo } from "react";
import { Marker } from "@react-google-maps/api";
import { AkiyaAkiya } from "./constants";

const CustomMarker = memo(
  ({
    position,
    isHovered,
    handleMarkerClick,
    handleMouseOver,
    handleMouseOut,
    id,
    draggable,
    onDragEnd,
  }) => {
    const scaledSize = isHovered
      ? new window.google.maps.Size(50, 50)
      : new window.google.maps.Size(40, 40);

    return (
      <Marker
        position={position}
        icon={{ url: AkiyaAkiya, scaledSize }}
        onClick={() => handleMarkerClick(id)}
        onMouseOver={() => handleMouseOver(id)}
        onMouseOut={handleMouseOut}
        draggable={draggable}
        onDragEnd={onDragEnd}
      />
    );
  }
);

export default CustomMarker;
