import React, {
  useEffect,
  useState,
  memo,
  useCallback,
  useMemo
} from "react";
import { GoogleMap, LoadScript, Marker, Circle } from "@react-google-maps/api";
import { useUser } from "../../Context/UserContext";
import CustomMarker from "./CustomMarker";
import {
  CENTER,
  API_KEY,
  MAP_CONTAINER_STYLE,
  EXPANDED_MAP_STYLE,
  backendToComponentFormat,
} from "./constants";
import {
  addFavoriteLocation,
  updateFavoriteLocation,
  deleteFavoriteLocation,
} from "./mapService";
import {
  ErrorMessage,
  LoadingIndicator,
  EmptyStateMessage,
  RadiusInput,
  ActionButtons,
  ConfirmationDialog,
} from "./UIComponents";
import { MunicipalityCounter } from "./MunicipalityCounter";

// Import your custom marker icon
const AkiyaAkiya = "/images/prefecture/AkiyaAkiya.png";

function MapComponent() {
  // Get user context
  const user = useUser();

  // Optimized state management
  const [favoriteLocations, setFavoriteLocations] = useState(() => 
    user?.fav_coordinates_and_proximity
      ? backendToComponentFormat(user.fav_coordinates_and_proximity)
      : []
  );

  const [municipalityCount, setMunicipalityCount] = useState(() => 
    user?.favourite_municipalities?.length || 0
  );

  // Rest of the existing state remains the same...
  const [addingLocation, setAddingLocation] = useState(false);
  const [newLocation, setNewLocation] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [oldLocation, setOldLocation] = useState(null);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [radius, setRadius] = useState(5);
  const [isEditing, setIsEditing] = useState(false);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [hoveredMarkerId, setHoveredMarkerId] = useState(null);
  const [isFirstTimeUser, setIsFirstTimeUser] = useState(false);
  const [actionType, setActionType] = useState(null);
  const [message, setMessage] = useState("");

  // Update radius when a location is selected
  useEffect(() => {
    if (selectedLocation) {
      setRadius(selectedLocation.radius);
    }
  }, [selectedLocation]);

  // Callback to show temporary messages
  const showMessage = useCallback((text) => {
    setMessage(text);
    setTimeout(() => setMessage(""), 3000);
  }, []);

  // Handler for adding a new location
  const handleAddLocation = () => {
    // Limit to 3 favorite locations
    if (favoriteLocations.length >= 3) {
      setError("You can only add a maximum of 3 favorite locations.");
      return;
    }

    setMessage("Adding a new location. Drag the marker to your desired spot.");
    setAddingLocation(true);
    setIsEditing(false);
    setSelectedLocation(null);
    
    // Set a new location slightly offset from the center
    setNewLocation({
      lat: CENTER.lat + 0.05,
      lng: CENTER.lng + 0.05,
      radius: 5,
    });
  };

  // Handler for selecting an existing location
  const handleSelectLocation = (coord) => {
    setMessage(
      "Location selected. You can now edit the radius or drag the marker."
    );
    setOldLocation(coord);
    setSelectedLocation(coord);
    setIsEditing(true);
    setAddingLocation(false);
    setNewLocation(null);
    setRadius(coord.radius);
  };

  // Handler for dragging an existing marker
  const handleMarkerDrag = (e) => {
    if (e.latLng && selectedLocation) {
      const newLat = e.latLng.lat();
      const newLng = e.latLng.lng();

      // Update selected location
      setSelectedLocation((prevLocation) => ({
        ...prevLocation,
        lat: newLat,
        lng: newLng,
      }));

      // Update locations list
      setFavoriteLocations((prevLocations) =>
        prevLocations.map((location) => {
          if (
            location.lat === oldLocation.lat &&
            location.lng === oldLocation.lng
          ) {
            return { ...location, lat: newLat, lng: newLng };
          } else {
            return location;
          }
        })
      );
      setMessage("Location updated. Please save the changes");
    }
  };

  // Handler for dragging a new location marker
  const handleNewLocationDrag = (e) => {
    if (e.latLng) {
      setNewLocation({
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
        radius: radius,
      });
    }
    setMessage("New location set. Confirm to save.");
  };

    // Optimized location update methods
    const optimisticUpdateLocations = useCallback((newLocationData) => {
      // Instantly update the locations without waiting for server response
      setFavoriteLocations(prevLocations => {
        // If replacing an existing location
        if (oldLocation) {
          return prevLocations.map(loc => 
            (loc.lat === oldLocation.lat && loc.lng === oldLocation.lng) 
              ? newLocationData 
              : loc
          );
        }
        // If adding a new location
        return [...prevLocations, newLocationData];
      });
    }, [oldLocation]);
  
  const confirmAddLocation = async () => {
    if (newLocation && user) {
      setLoading(true);
      
      // Optimistic UI update
      const optimisticLocation = {
        ...newLocation,
        id: `temp-${Date.now()}` // Temporary ID
      };
      optimisticUpdateLocations(optimisticLocation);

      try {
        const result = await addFavoriteLocation(newLocation, user.email);
        
        if (result.success) {
          // Replace the optimistic location with the actual server response
          setFavoriteLocations(result.locations);
          setMunicipalityCount(user?.favourite_municipalities?.length || 0);
          setNewLocation(null);
          setIsFirstTimeUser(false);
          showMessage("New location successfully added!");
        } else {
          // Revert if server update fails
          setFavoriteLocations(prevLocations => 
            prevLocations.filter(loc => loc.id !== optimisticLocation.id)
          );
          setError(result.error);
        }
      } catch (err) {
        // Revert if there's a network error
        setFavoriteLocations(prevLocations => 
          prevLocations.filter(loc => loc.id !== optimisticLocation.id)
        );
        setError("Failed to add location. Please try again.");
      } finally {
        setLoading(false);
        setAddingLocation(false);
        setConfirmDialog(false);
      }
    }
  };


  // Confirm editing an existing location with optimistic update
  const confirmEditLocation = async () => {
    if (selectedLocation && user && oldLocation) {
      setLoading(true);
      
      const updatedLocation = { ...selectedLocation, radius };
      
      // Optimistic UI update
      optimisticUpdateLocations(updatedLocation);

      try {
        const result = await updateFavoriteLocation(
          oldLocation,
          updatedLocation,
          user.email
        );
        
        if (result.success) {
          setFavoriteLocations(result.locations);
          setMunicipalityCount(user?.favourite_municipalities?.length || 0);
          showMessage("Location successfully updated!");
        } else {
          // Revert if server update fails
          setFavoriteLocations(prevLocations => 
            prevLocations.map(loc => 
              (loc.lat === selectedLocation.lat && loc.lng === selectedLocation.lng)
                ? oldLocation
                : loc
            )
          );
          setError(result.error);
        }
      } catch (err) {
        // Revert if there's a network error
        setFavoriteLocations(prevLocations => 
          prevLocations.map(loc => 
            (loc.lat === selectedLocation.lat && loc.lng === selectedLocation.lng)
              ? oldLocation
              : loc
          )
        );
        setError("Failed to update location. Please try again.");
      } finally {
        setLoading(false);
        setSelectedLocation(null);
        setIsEditing(false);
        setConfirmDialog(false);
        setOldLocation(null);
      }
    }
  };

  // Handle delete with optimistic update
  const handleDelete = async () => {
    if (selectedLocation && user) {
      setLoading(true);
      
      // Optimistic UI update
      const remainingLocations = favoriteLocations.filter(
        loc => loc.lat !== selectedLocation.lat || loc.lng !== selectedLocation.lng
      );
      setFavoriteLocations(remainingLocations);

      try {
        const result = await deleteFavoriteLocation(selectedLocation, user.email);
        
        if (result.success) {
          setFavoriteLocations(result.locations);
          setMunicipalityCount(user?.favourite_municipalities?.length || 0);
          setIsFirstTimeUser(result.locations.length === 0);
          showMessage("Location successfully deleted.");
        } else {
          // Revert if server update fails
          setFavoriteLocations(prevLocations => [...prevLocations, selectedLocation]);
          setError(result.error);
        }
      } catch (err) {
        // Revert if there's a network error
        setFavoriteLocations(prevLocations => [...prevLocations, selectedLocation]);
        setError("Failed to delete location. Please try again.");
      } finally {
        setLoading(false);
        setSelectedLocation(null);
        setIsEditing(false);
        setConfirmDialog(false);
      }
    }
  };



  // Handler for radius input change
  const handleRadiusChange = (event) => {
    let newRadius = Number(event.target.value);
    setRadius(newRadius);

    // Update radius for selected or new location
    if (selectedLocation) {
      setSelectedLocation((prevLocation) => ({
        ...prevLocation,
        radius: newRadius,
      }));
    }

    if (newLocation) {
      setNewLocation((prevLocation) => ({
        ...prevLocation,
        radius: newRadius,
      }));
    }
    setMessage("Radius updated.");
  };

  // Confirm action handler
  const handleConfirm = () => {
    setConfirmDialog(false);
    if (actionType === "save") {
      confirmEditLocation();
    } else if (actionType === "delete") {
      handleDelete();
    } else if (actionType === "add") {
      confirmAddLocation();
    }
    setActionType(null);
  };

  // Cancel action handler
  const handleCancel = () => {
    setConfirmDialog(false);
    setAddingLocation(false);
    setIsEditing(false);
    setSelectedLocation(null);
    setNewLocation(null);
    setRadius(5);
    setActionType(null);
    setMessage("Action cancelled.");
  };

  // Handlers for various button clicks
  const handleSaveChangesClick = () => {
    setActionType("save");
    setConfirmDialog(true);
    setMessage("Confirm to save changes?");
  };

  const handleDeleteLocationClick = () => {
    setActionType("delete");
    setConfirmDialog(true);
    setMessage("Are you sure you want to delete this location?");
  };

  const handleAddLocationClick = () => {
    setActionType("add");
    setConfirmDialog(true);
    setMessage("Confirm to add this location?");
  };

  // Map load handler
  const handleMapLoad = () => {
    setMapLoaded(true);
  };

  // Marker interaction handlers
  const handleMarkerClick = (id) => {
    const location = favoriteLocations.find(
      (loc) => `${loc.lat}-${loc.lng}` === id
    );
    if (location) {
      handleSelectLocation(location);
    }
  };

  const handleMouseOver = (id) => {
    setHoveredMarkerId(id);
  };

  const handleMouseOut = () => {
    setHoveredMarkerId(null);
  };

  // Memoized map center calculation
  const mapCenter = useMemo(() => {
    if (addingLocation && newLocation) return newLocation;
    if (isEditing && selectedLocation) return selectedLocation;
    return favoriteLocations.length > 0
      ? { lat: favoriteLocations[0].lat, lng: favoriteLocations[0].lng }
      : CENTER;
  }, [addingLocation, newLocation, isEditing, selectedLocation, favoriteLocations]);

  // Memoized markers
  const memoizedMarkers = useMemo(() =>
    favoriteLocations.map((coord) => (
      <CustomMarker
        key={`${coord.lat}-${coord.lng}`}
        position={{ lat: coord.lat, lng: coord.lng }}
        isHovered={hoveredMarkerId === `${coord.lat}-${coord.lng}`}
        handleMarkerClick={handleMarkerClick}
        handleMouseOver={handleMouseOver}
        handleMouseOut={handleMouseOut}
        id={`${coord.lat}-${coord.lng}`}
        draggable={
          isEditing &&
          selectedLocation?.lat === coord.lat &&
          selectedLocation?.lng === coord.lng
        }
        onDragEnd={(e) => {
          if (e.latLng) {
            handleMarkerDrag(e);
          }
        }}
      />
    )),
    [favoriteLocations, hoveredMarkerId, isEditing, selectedLocation]
  );

  // Memoized circles
  const memoizedCircles = useMemo(() =>
    favoriteLocations.map((coord) => (
      <Circle
        key={`${coord.lat}-${coord.lng}`}
        center={{ lat: coord.lat, lng: coord.lng }}
        radius={
          (isEditing &&
          selectedLocation?.lat === coord.lat &&
          selectedLocation?.lng === coord.lng
            ? radius
            : coord.radius) * 1000
        }
        options={{
          fillColor:
            selectedLocation?.lat === coord.lat &&
            selectedLocation?.lng === coord.lng
              ? "blue"
              : "red",
          fillOpacity: 0.2,
          strokeColor:
            selectedLocation?.lat === coord.lat &&
            selectedLocation?.lng === coord.lng
              ? "blue"
              : "red",
          strokeOpacity: 1,
          strokeWeight: 1,
        }}
        onClick={() => handleSelectLocation(coord)}
      />
    )),
    [favoriteLocations, isEditing, radius, selectedLocation]
  );

  // Render the component
  return (
    <div className="flex w-full items-center justify-center bg-gray-50 min-h-screen">
      <div className="max-w-[1440px] items-center justify-center w-full my-[3rem] px-4">
        <LoadScript
          googleMapsApiKey={API_KEY}
          onLoad={() => console.log("Script loaded successfully")}
        >
          <div className="flex flex-col items-center space-y-6">
            {/* Error Message */}
            <ErrorMessage error={error} onClose={() => setError(null)} />

            {/* Loading Indicator */}
            {loading && <LoadingIndicator />}

            {/* Municipality Counter */}
            <MunicipalityCounter
              municipalityCount={municipalityCount}
              isLoading={loading}
            />

            {/* Empty State Message */}
            <EmptyStateMessage
              isFirstTimeUser={
                !loading &&
                favoriteLocations.length === 0 &&
                !error &&
                isFirstTimeUser
              }
            />

            {/* Google Map Container */}
            <div className="w-full rounded-xl overflow-hidden">
              <GoogleMap
                mapContainerStyle={
                  addingLocation || isEditing
                    ? EXPANDED_MAP_STYLE
                    : MAP_CONTAINER_STYLE
                }
                center={mapCenter}
                zoom={favoriteLocations.length > 0 ? 8 : 6}
                onLoad={handleMapLoad}
                options={{
                  streetViewControl: false,
                  mapTypeControl: false,
                }}
              >
                {/* Render markers and circles */}
                {mapLoaded && memoizedMarkers}
                {mapLoaded && memoizedCircles}

                {/* Render new location marker when adding */}
                {mapLoaded &&
                  addingLocation &&
                  newLocation && (
                    <div>
                      <Marker
                        position={newLocation}
                        draggable
                        onDragEnd={handleNewLocationDrag}
                        icon={{
                          url: AkiyaAkiya,
                          scaledSize: new window.google.maps.Size(50, 50),
                        }}
                      />
                      <Circle
                        center={newLocation}
                        radius={radius * 1000}
                        options={{
                          fillColor: "blue",
                          fillOpacity: 0.2,
                          strokeColor: "blue",
                          strokeOpacity: 1,
                          strokeWeight: 1,
                        }}
                      />
                    </div>
                  )}
              </GoogleMap>
            </div>

            {/* Radius Input */}
            <RadiusInput radius={radius} onChange={handleRadiusChange} />

            {/* Action Buttons */}
            <ActionButtons
              addingLocation={addingLocation}
              isEditing={isEditing}
              onAddLocation={handleAddLocation}
              onAddLocationClick={handleAddLocationClick}
              onSaveChangesClick={handleSaveChangesClick}
              onDeleteLocationClick={handleDeleteLocationClick}
            />

            {/* Confirmation Dialog */}
            {confirmDialog && (
              <ConfirmationDialog
                message={message}
                onConfirm={handleConfirm}
                onCancel={handleCancel}
              />
            )}
          </div>
        </LoadScript>
      </div>
    </div>
  );
}

export default memo(MapComponent);