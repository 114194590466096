
import React, { useRef, useState, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import CustomButt from "../CustomButton/CustomButt";

const fields = [
  { type: "text", placeholder: "Your name", name: "name" },
  { type: "email", placeholder: "Your email", name: "email" },
  { type: "text", placeholder: "Your Country", name: "country" },
  { type: "text", placeholder: "Tell us all about it", name: "query" },
];

const QueryForm = () => {
  const formRef = useRef();
  const [showMessage, setShowMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [currentUrl, setCurrentUrl] = useState('');

  useEffect(() => {
    setCurrentUrl(window.location.href);
  }, []);

  const sendEmail = async (e) => {
    e.preventDefault();
  
    const formData = new FormData(formRef.current);
    formData.append("page_url", currentUrl);
    
    const jsonData = Object.fromEntries(formData);
  
    // Add current date and time in the specified format
    const now = new Date();
    const formattedDate = now.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'long',
      year: 'numeric'
    }); // Example: "14 August 2024"
    const formattedTime = now.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    }); // Example: "12:00 PM"
  
    jsonData.date = formattedDate;
    jsonData.time = formattedTime;
  
    try {
      await emailjs.sendForm(
        'service_sf1j1kl', 
        'template_4hxx0oe', 
        formRef.current, 
        { publicKey: 's0xkd41sUOcqpCOVk' }
      );
      
      // Send data to Google Sheets
      fetch("https://script.google.com/macros/s/AKfycbyRRgLNm2qMDlrmIZac3HE_pWSZ1MV5HcS-0610JH4Ues67auASMS1OAb2iieIgsn2z/exec", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        mode: "no-cors",
        body: JSON.stringify(jsonData)
      }).catch(console.error);
  
      setShowMessage(true);
      setTimeout(() => {
        setShowMessage(false);
        window.location.href = '/';
      }, 5000);
    } catch (error) {
      console.error('FAILED...', error.text);
      setErrorMessage(true);
      setTimeout(() => setErrorMessage(false), 5000);
    }
  };


  return (
    <div className="relative">
      <form ref={formRef} onSubmit={sendEmail} className="w-full flex flex-col gap-4">
        {fields.map((field, index) => (
          <input
            key={index}
            type={field.type}
            placeholder={field.placeholder}
            name={field.name}
            required
            className="w-full h-16 border-b-[1px] text-sm outline-none"
          />
        ))}
        
        <div>
          <CustomButt text="Submit" />
        </div>
      </form>
      
      {showMessage && (
        <div className="absolute bottom-4 left-4 bg-green-100 text-green-700 py-2 px-4 rounded-lg">
          <p>Thank you for your message! Our friendly staff will contact you soon!</p>
        </div>
      )}
      
      {errorMessage && (
        <div className="absolute bottom-4 left-4 bg-red-100 text-red-700 py-2 px-4 rounded-lg">
          <p>Due to a technical issue, your inquiry was not sent. Please contact us directly at "lester.goh@akiya2.com"! Apologies.</p>
        </div>
      )}
    </div>
  );
};

export default QueryForm;
