import React, { createContext, useState, useContext, useEffect } from "react";
import {
  fetchDataWithToken,
  updateDataWithToken,
  updateImageWithToken,
} from "../Utils/CommonFunctions";

// Creating the UserContext
const UserContext = createContext();

// UserContext Provider component
const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isToken, setIsToken] = useState(false);

  useEffect(() => {
    // Fetch user data asynchronously
    try {
      const access_token = localStorage.getItem("access_token");

      fetchDataWithToken(
        "https://app.akiya2.com/get-user-data",
        access_token
      ).then((data) => {
        if (data) {
          setUser(data);
        } else {
          setIsToken(false); // Update isToken instead of removing the token
          localStorage.removeItem("access_token");
        }
      });
    } catch (error) {
      // console.log(error.message);
    }
  }, [isToken]); // Empty dependency array ensures this effect runs only once, on component mount

  const updateUser = (newUserData) => {
    try {
      const access_token = localStorage.getItem("access_token");

      updateDataWithToken(
        "https://app.akiya2.com/update-user-data",
        access_token,
        newUserData
      ).then((data) => {
        if (data) {
          // console.log(data);
          setUser(data);
        } else {
          setIsToken(false); // Update isToken instead of removing the token
          // console.log("problem in 1");
          localStorage.removeItem("access_token");
        }
      });
    } catch (error) {
      // console.log(error.message);
    }
  };

  const updateUserImage = async (imageFile) => {
    try {
      const access_token = localStorage.getItem("access_token");
      if (!access_token) {
        throw new Error("Access token not found");
      }

      const response = await updateImageWithToken(
        "https://app.akiya2.com/update-user-image",
        access_token,
        imageFile
      );

      // If the update was successful, handle it accordingly
      if (response && response.message === "User image updated successfully") {
        setIsToken(true);
        // console.log(response);

        // Perform any additional actions after successful update
      } else {
        throw new Error("Failed to update user image");
      }
    } catch (error) {
      console.error("Error updating user image:", error.message);
      // Handle errors gracefully
    }
  };

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        updateUser,
        updateUserImage, // Expose updateUserImage function in the context
        showModal,
        setShowModal,
        isToken,
        setIsToken,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

// Custom hook to use the UserContext
const useUser = () => {
  const context = useContext(UserContext);
  if (context) {
    return context.user;
  }
  return null;
};

const useUpdateUser = () => {
  const context = useContext(UserContext);
  if (context) {
    return context.updateUser;
  }
  return null;
};

const useUpdateUserImage = () => {
  const context = useContext(UserContext);
  if (context) {
    return context.updateUserImage;
  }
  return null;
};

const useRefreshUser = () => {
  const context = useContext(UserContext);
  return context ? context.refreshUser : null;
};

export {
  UserProvider,
  useUser,
  useUpdateUser,
  useUpdateUserImage,
  UserContext,
  useRefreshUser,
};