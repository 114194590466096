import React, { useState, useEffect } from "react";
import { fetchData } from "../../Utils/CommonFunctions";
import Loader from "../Loader/Loader";
import NewsCard from "./NewsCards/NewsCard";

function AkiyaOnNews() {
  const [latestBlogData, setLatestBlogData] = useState([]);
  const [latestNews, setLatestNews] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDataAsync = async () => {
      try {
        const blogResponse = await fetchData("https://app.akiya2.com/blogs");
        const newsResponse = await fetchData("https://app.akiya2.com/all_news");

        const sortedBlogData = blogResponse
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          .slice(0, 5);

        setLatestBlogData(sortedBlogData);
        setLatestNews(newsResponse);
        setLoading(false); // Set loading to false once data is fetched
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false); // Set loading to false in case of error
      }
    };
    fetchDataAsync();
  }, []);

  return (
    <div>
      <div className="bg-[#5ab963] flex justify-center relative ">
        <div className="md:ml-[3rem] ml-[0.5rem] text-[#fff] pb-[2.5rem] w-[100%] max-w-[1440px]">
          <p className="m-0 md:text-[24px] text-[12px] font-[800] pt-[0.5rem]">
            Follow Along with us
          </p>
          <h1 className="text-[72px] font-[800] leading-[78px] pt-[0.5rem] pb-[7rem] second">
            Akiya2.0 in the spotlight
          </h1>
        </div>
      </div>
      
      {/* Position this div with a negative margin-top */}
      <div className="w-[100vw] flex justify-center items-center flex-col mt-[-10rem] relative z-10">
        <div className="max-w-[1440px] w-[95%]">
          {loading ? (
            <Loader />
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 my-10">
              {[...latestNews].reverse().map((newsItem, index) => (
                <NewsCard key={index} item={newsItem} />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AkiyaOnNews;