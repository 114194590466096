import React from "react";

export const MunicipalityCounter = ({ municipalityCount, isLoading }) => {
  return (
    <div className="w-full bg-green-50 p-4 rounded-lg shadow mb-4">
      <div className="flex items-center justify-center">
        <div className="text-center">
          <h3 className="text-lg font-semibold text-gray-800 mb-1">
            Municipalities in Your Area
          </h3>
          <div className="flex items-center justify-center">
            {isLoading ? (
              <div className="inline-block animate-spin rounded-full h-5 w-5 border-t-2 border-green-500 mr-2"></div>
            ) : (
              <div className="bg-white px-4 py-2 rounded-lg shadow-sm border border-green-200">
                <span className="text-2xl font-bold text-green-600">{municipalityCount}</span>
                <span className="ml-2 text-gray-600">municipalities covered</span>
              </div>
            )}
          </div>
          <p className="text-sm text-gray-600 mt-2">
            Adjust your locations and radius to cover more or fewer areas
          </p>
        </div>
      </div>
    </div>
  );
};
